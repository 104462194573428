
import "./contactsCard.css";
import { Link } from "react-router-dom";

const ContactsCard = ({contact}) => {
  return (
    <div >
      <Link to={contact.url} className="contactCard flex" target="_blank" rel="noopener noreferrer">
          <span className="icon">{contact.icon}</span>
          <h4 className="title">{contact.type}</h4>
          <p className="description">: {contact.content}</p>
      </Link>
    </div>
  )
}

export default ContactsCard