import ServicesCard from "./ServicesCard/ServicesCard";
import "./services.css";
import { announceText } from "../../utils/data";
import { services } from "../../utils/data";
import { Link } from "react-router-dom";
import Landing from '../Landing/Landing';
import { landings } from '../../utils/data';

const Services = () => {
  return (
   <>
       <Landing landings={landings[1]}/>
    <div className="services-container">
        <h1 className="announce-title">Découvrez nos différents services</h1>
        <p className="announce-text">{announceText[0].text1}</p>
        <div className="only-main-page">
          <p className="text">Vous servir est notre passion et vous satisfaire est notre mission.</p>
       </div>
        <div className="services flex">
          {services.map((service,index) =>
            <div className="service" key={index}>
              <ServicesCard service={service}/>
            </div>
          )}
        </div>
        <div className="services-bottom flex">
          <h3 className="bottom-text">
            En plus de tout ça, pour tout autre besoin concernant vos 
            appareils,faites-nous savoir car nous ne manquons pas de services
          </h3>
          <Link to={"/services"} className="btn"><span>demander un service</span> <i className="fa fa-chevron-right"></i></Link>
        </div>
    </div>
   </>
  )
}

export default Services