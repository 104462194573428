import {menus}  from "../../../utils/data";
import {contacts}  from "../../../utils/data";
import logo from "../../../images/logo.png"
import "./navbar.css"
import { Link,useNavigate } from "react-router-dom";
import { useState } from "react";

const Navbar = () => {
  const [borderBottom, setBorderBottom] = useState(0);
  const [toggle, setToggle] = useState(false);

  const handleClick =index => {
    setBorderBottom(index);
    setToggle(!toggle);
  }

  const navigate=useNavigate()

  const redirectUser=e=>{
    e.preventDefault();
    navigate("/contacts");
  }
  return (
    <div className='navbar flex'>
      <div className="logo">
        
          <Link to="/home">
           <img src={logo} alt="logo de l'entreprise" />
          </Link>
      </div>
      
        <ul 
        className={toggle?"menu-container active flex":"menu-container flex"}
        >
          {menus.map((item,index)=>
            <li key={index} 
            className={borderBottom===index?"border":""} 
            onClick={()=>handleClick(index)}>
              <Link to={item.path}>{item.title}</Link>
            </li>
          )
          }
        </ul>
        <form  className="form flex">
          <input type="text" placeholder="Ecrire votre service ici..."/>
          <button className="btn"
          onClick={(e)=>redirectUser(e)}
          >demander un sevice</button>
        </form>
        <div className={!toggle? "icon-container":"icon-container show"}>
            {contacts.map((icon, index)=>
              <Link key={index} to={icon.url}>{icon.icon}</Link>
            )}
        </div>
        <div className="toggle-bars" onClick={()=>setToggle(!toggle)}>
          <i className={!toggle?"fa fa-bars":"fa fa-times"}></i>
        </div>
      </div>
      
  
  )
}

export default Navbar