
import { landings } from '../../utils/data';
import Landing from '../Landing/Landing';
import Councils from './Councils/Councils';
import Services from '../Services/Services';
import Team from '../Team/Team';
import Testimonials from '../Testimonials/Testimonials';
import Contacts from '../Contacts/Contacts';
import "./home.css"

const Home = () => {
  return (
    <div >
      <Landing landings={landings[0]} />
      <div className="components-container">
      <Councils />
      <Services/>
      <Team/>
      <Testimonials/>
      <Contacts />
      </div>
    </div>
  )
}

export default Home