import "./ServicesCard.css"

const ServicesCard = ({service}) => {
  return (
    <div className="serviceCard card animate-on-scroll">
      <div className="card-img">
        <img src={service.image} alt={service.title} />
        <h4 className="card-title">{service.title}</h4>
      </div>
      <p className="card-description">{service.description}</p>
    </div>
  )
}

export default ServicesCard