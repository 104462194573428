import "./councils.css";
import money from "../../../images/money/money.webp"
import nature from "../../../images/nature/nature1.jpg"
import recyclage from "../../../images/recyclage/recyclage3.jpg"
import reparation from "../../../images/reparation/reparation-icon.jpg"

const Councils = () => {
  return (
    <div className="councils-container">
        <div className="council-box">
            <h1 className="council-text">Plus un appareil est entretenu ou réparé,plus on peut faire des économies</h1>
            <div className="council-example flex">
                <p className="council-img">
                    <img src={reparation } alt="illustration " />
                </p>
                <i className="fa fa-arrow-right"></i>
                <p className="council-img">
                    <img src={money } alt="illustration " />
                </p>
            </div>
        </div>
        <div className="council-box">
        <h1 className="council-text">Plus un appareil dure à la maison,plus on peut protéger l'envirronnement contre la pollution</h1>
            <div className="council-example flex">
                <p className="council-img">
                    <img src={recyclage} alt="illustration " />
                </p>
                <i className="fa fa-arrow-right"></i>
                <p className="council-img">
                    <img src={nature} alt="illustration " />
                </p>
            </div>
        </div>
    </div>
  )
}

export default Councils