import "./TeamCard.css"

const TeamCard = ({team}) => {
  return (
    <div className="team-card card animate-on-scroll">
        <div className="team-img">
          <img src={team.img} alt={team.fullName} />
        </div>
        <div className="team-details">
          <h4 className="title">{team.fullName}</h4>
          <p className="card-description ">{team.description}</p>
        </div>
    </div>
  )
}

export default TeamCard