import landing from '../images/landing/landing.jpg'
import landing1 from '../images/landing/landing1.jpg'
import landing2 from '../images/landing/landing2.jpg'
import landing3 from '../images/landing/landing3.jpg'

import servImg1 from "../images/achat/achat.jpg"
import servImg2 from "..//images/installation/installation1.jpg"
import servImg3 from "../images/reparation/reparation1.jpg"
import servImg4 from "../images/reparation/reparation2.jpg"
import servImg5 from "../images/desinstallation/desinstallation.webp"
import servImg6 from "../images/installation/installation-electric.jpg"
import servImg7 from "../images/installation/plomberie.jpg"
import servImg8 from "../images/plomberie/canalisation.webp"
import servImg9 from "../images/plomberie/jardinage.jpg"

import team1 from "../images/team/team-5.jpg"
import team2 from "../images/team/team3.webp"
import team3 from "../images/team/team4.webp"
import team4 from "../images/team/teams.webp"


import testimonialImg1 from "../images/client/client2.webp"
import testimonialImg2 from "../images/client/client3.webp"
import testimonialImg3 from "../images/client/client4.webp"
import testimonialImg4 from "../images/client/client5.webp"



export const menus=[
  {
    title:"Accueil",
    path:"/"
  },
  {
    title:"Services",
    path:"/services"
  },
  {
    title:"Notre équipe",
    path:"/team"
  },
  {
    title:"Infos-contacts",
    path:"/contacts"
  }
];

export const landings =[
  {img: landing},
  {img: landing1},
  {img: landing2},
  {img: landing3},
];

export const announceText =[
  {text1:"La confiance mutuelle ne peut s'établir entre deux entittés sans preuve de loyauté et du respect des engagements . Ainsi en vous proposant nos gammes de service , nous nous obligeons pour un meilleur travail et le tout à des prix raisonnables"},
  {text2:"en connaissant l'importance du défi lié à la gestion des appareils domestiques et votre besoin à ce que celle-ci soit faite de la meilleure des manières , Haba technicien a mis en place un groupe ultra-dynamique pour répondre à vos attentes."},
  {
    text3:"La qualité d'une entreprise se mesure par la satisfaction qu'elle peut apporter à ses clients. Ainsi nous vous mettons à l'écoute de quelques-uns d'entre eux pour comprendre réellement ce que nous faisons et jusqu'où nous sommes capables de vous apporter le meilleur."
  },
  {text4:"pour être plus proche de vous et être à votre écoute à tout moment, nous vous offrons plusieurs moyens de communication pour vous faciliter à nous joindre ."}
];
export const services=[
  { image:servImg1,
    title:"Achat",
  description:"Dans le souci de trouver les meilleurs produits sur le marché , vous pouvez nous confier l'achat de vos équipements domestiques jusqu'a la livraison et tout ce qui s'en suit partout dans le pays. "},
  { image:servImg2,
    title:"Installation",
  description:"Vous ne devez plus vous soucier de comment installer ou organiser vos appareils. Puisque votre sécurité, celle de l'appareil et de votre famille en dépendent , le choix d'un agent spécialisé et compétent reste nécessaire. Raison pour laquelle nous nous engageons à vos côtés pour une sécurisation optimale. "},
  { image:servImg3,
    title:"Entretien",
  description:"Plus vous entretenez régulièrement vos appareils,  moins ils ont la chance de tomber en panne. Et en ce sens nous  proposons aussi un service , tout ce qui tourne autour du lavage jusqu'au nettoyage des machines. "},
  { image:servImg4,
    title:"Dépannage/Réparation",
  description:"Toute machine peut indéniablement tomber en panne. Mais leur réparation aussi peut prolonger leur durée de vie et ainsi continuer à faire des économies et protéger l'environnement contre les dechets. Cela fait que nous disposons une variété de services assez complexe et une connaissance suffisamment solide dans le dépannage des appareils pour les faire tourner le plus longtemps possible. "},
  { image:servImg5,
    title:"Désinstallation",
  description:"Lorsque votre appareil ne peut plus servir ou est hors usage, la bonne chose à faire n'est pas de le jeter dans la nature car ces appareils sont difficilement désintégrables ce qui peut être un problème pour la sécurité environnémentale. De ce fait, notre groupe se met à votre disposition pour démonter et amener vos appareils hors usages dans les endroits les mieux indiqués ou les faire recycler lorsque cela est nécessaire."},
  {
    image:servImg6,
    title:"Electricité domestique",
    description:"Que ce soient les installations électriques de vos maisons, le remplacement des câbles vieillissants,contrôle d'adaptabilité entre câbles et appareils éléctromenagers , le choix des équipements de meilleure qualité ,leur entretien et bien plus encore...Ne cherchez plus rien ailleurs, contactez-nous simplement pour avoir les solutions les mieux adaptées à vos problèmes"
  },
  {
    image:servImg7,
    title:"La plombérie",
    description:"Haba technicien est la meilleure entreprise privée de gestion domestique en Côte d'Ivoire . Nous choisir ne peut jamais être un regret . Nous vous offrons les meilleures architectures de canalisation dans vos maisons et partout où vous en avez besoin, les pots , les robinets , l'arrosage des jardins, des gazons, le curage des tuyaux, le nettoyage des cuves...Nous faisons le tout en un seul . Faites-nous confiance pour bâtir le meilleur ensemble. "
  },
  {
    image:servImg8 ,
    title:"Débouchage ",
    description:"Les plombiers utilisent des méthodes variées, telles que les furets mécaniques, les hydrojets à haute pression, et les produits chimiques spécialisés, pour éliminer les obstructions causées par des débris, des racines, ou des dépôts calcaires. L'utilisation de caméras d'inspection permet une évaluation précise de l'état des canalisations"
  },
  {
    image:servImg9,
    title:"Le Jardinage",
    description:"Un jardinier est un professionnel spécialisé dans la culture, l'entretien et la gestion des espaces verts. Ses responsabilités incluent la préparation du sol, la sélection et la plantation des plantes, l'arrosage et la gestion de l'irrigation. Il assure l'entretien général, protège contre les ravageurs de manière respectueuse de l'environnement, pratique la rotation des cultures, et utilise le compostage pour enrichir le sol.",
  }
];
export const team=[
  {
    img:team1,
    fullName:'Tolon Haba',
    description:"Tolon Haba - Technicien Certifié en Climatisation,Fort de sa certification en climatisation,  Haba est un technicien compétent doté de huit années d'expérience. Spécialisé dans le dépannage rapide et efficace des systèmes de climatisation, il est reconnu pour son approche méthodique et son service client exceptionnel."
  },
  {
    img:team2,
    fullName:"Guillaume Alfred",
    description:"Fort d'une formation avancée en diagnostic rapide, Alfred excelle dans l'identification rapide des problèmes. Ses multitudes d'expérience font de lui un atout précieux pour résoudre efficacement les problèmes électroménagers et de réfrigération."
  },
  {
    img:team3,
    fullName:"Aboubacar Konan",
    description:"Avec ses multiples connaissances en ingénierie énergétique, ce génie apporte une expertise approfondie en matière de systèmes énergétiques. Ses tois  ans d'expérience dans l'optimisation des performances énergétiques des équipements de froid font de lui un leader dans le domaine."
  },
  {
    img:team4,
    fullName:"Karim traoré",
    description:"Karim possède une expertise approfondie en réparation d'appareils ménagers avec plusieurs années d'expérience. Sa passion pour résoudre les problèmes techniques et sa grande compétence en font un technicien de confiance pour les clients ."
  }
];
export const testimonials=[
  {
    image:testimonialImg1,
    fullName:"Rafael Smith",
    comment:"Mon expérience avec le technicien de froid électroménager a été exceptionnelle. Sa connaissance pointue des systèmes de réfrigération et son approche méthodique ont rapidement résolu le problème de mon réfrigérateur. Un professionnel remarquable !"
  },
  {
    image:testimonialImg3 ,
    fullName:"Sofi Kourouma",
    comment:"Un grand merci au technicien de froid qui est intervenu chez moi. Sa compétence technique et son professionnalisme ont été très rassurants. Il a pris le temps d'expliquer la nature du problème et a effectué les réparations avec une efficacité remarquable."
  },
  {
    image:testimonialImg2,
    fullName:"Moussa Camara",
    comment:"Cette entreprise est vraiment douée dans son domaine. Les services sont de très bonnes qualités . Surtout ils sont à l'écoute des clients . Donc faites-les confiance comme moi ."
  },
  {
    image:testimonialImg4,
    fullName:"Edouard Nuss",
    comment:"Le technicien qui a pris en charge la réparation de mon congélateur était non seulement compétent mais également très sympathique. Sa personnalité agréable a contribué à rendre l'expérience de réparation beaucoup plus agréable."
  }
];

export const contacts=[
  {type:"WhatsApp",
  icon:<i className='fa-brands fa-whatsapp'></i>,
  content:"+225 0566764951",
  url:"https://wa.me/+2250566764951"
},
  {type:"Téléphone",
  icon:<i className='fa fa-phone'></i>,
  content:"+225 0566764951",
  url:"tel:+2250566764951"
},
  {type:"Facebook",
  icon:<i className='fa-brands fa-facebook'></i>,
  content:"Haba froid",
  url:"https://www.facebook.com/profile.php?id=100089938373722&mibextid=ZbWKwL"
},
  {type:"Email",
  icon:<i className='fa fa-envelope'></i>,
  content:"tolonwidoh@gmail.com",
  url:"mailto:tolonwidoh@gmail.com"
}
];

export const footer =[
  {
    title:"Contacts",
    text:[ 
      {
        name:"WhatsApp",
        url:"https://wa.me/+2250566764951"
      },
      {
        name:"Téléphone",
        url:"tel:+2250566764951"
      },
      {
        name:"Facebook",
        url:"https://www.facebook.com/profile.php?id=100089938373722&mibextid=ZbWKwL"
      },
      {
        name:"Email",
        url:"mailto:tolonwidoh@gmail.com"
      } ]
  },
  {
    title:"Services",
    text:["Installation", "Entretien", "Dépannage/Réparation", "Désinstallation","Plombérie","Débouchage "]
     
      
  }
]