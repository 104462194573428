import "./testimonialCard.css"
const TestimonialCard = ({testimonial}) => {

  return (
    <div className="testimonial-card card" >
      <div className="wrapper">
        <div className="card-img">
            <img src={testimonial.image} alt={testimonial.fullName} />
        </div>
        <div className="infos flex">
        <h4 className="card-titl" >{testimonial.fullName}</h4>
        <i className="fa fa-check"></i>
        </div>
      </div>
      <p className="card-description">{testimonial.comment}</p>
    </div>
  )
}

export default TestimonialCard;