
import { BrowserRouter,Routes , Route,useLocation} from 'react-router-dom';
import { useEffect } from 'react';
import './App.css';
import Header from "./components/Common/Navbar/Navbar";
import Home from "./components/Home/Home"
import Services from "./components/Services/Services"
import Team from "./components/Team/Team"
import Contacts from "./components/Contacts/Contacts"
import Footer from "./components/Common/Footer/Footer"

function App() {
  const ScrollOnTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };

  return (
    <BrowserRouter>
     <Header/> 
        <ScrollOnTop/>
      <Routes>
        <Route index path="/" element={<Home/>}></Route>
        <Route  path="/services" element={<Services/>}></Route>
        <Route  path="/team" element={<Team/>}></Route>
        <Route  path="/contacts" element={<Contacts/>}></Route>
        <Route  path="*" element={<Home/>}></Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
