import ContactsCard from "./ContactsCard/ContactsCard";
import { announceText } from "../../utils/data";
import { contacts } from "../../utils/data";
import Landing from '../Landing/Landing';
import { landings } from '../../utils/data';
import "./contacts.css"

const Contacts = () => {
  return (
   <>
   <Landing landings={landings[3]}/>
    <div className="contacts-container">
      <h1 className="announce-title">Comment nous contacter ?</h1>
        <p className="announce-text">{announceText[3].text4}</p>
        <div className="only-main-page">
          <p className="text">Haba technicien est une entreprise spécialisée dans la gestion des appareils électroménagers et domestiques qui fait déjà les preuves de son avoir-faire à Abidjan et dans d'autres villes de la Côte d'Ivoire. Son siège social se trouve à Abidjan quartier Cocody. Pour tout besoin d'informations supplémentaires , veuillez nous contacter via l'une des adresses ci-dessous.</p>
       </div>
        <div className="contacts flex">
          {contacts.map((contact,index) =>
            <div className="contact" key={index}>
              <ContactsCard contact={contact}/>
            </div>
          )}
        </div>
        <h4 className="announce-title">Contactez-nous dès maintenant et dites nous ce que vous voulez qu'on fasse pour vous et comment vous voulez qu'on le fasse pour vous .</h4>
    </div>
   </>
  )
}

export default Contacts