import { Link } from "react-router-dom";
import { testimonials } from "../../utils/data";
import TestimonialCard from "./testimonialCard/TestimonialCard";
import { announceText } from "../../utils/data";
import "./testimonials.css";
const Testimonials = () => {
  return (
    <div>
        <h1 className="announce-title">Quelques témoignages qui illustrent l'étendu de notre champs d'action et la confiannce qui nous lie à nos clients</h1>
        <p className="announce-text">{announceText[2].text3}</p>
        <div className="testimonials flex">
          {testimonials.map((testimonial,index) =>
            <div className="testimonial" key={index}>
              <TestimonialCard testimonial={testimonial}/>
            </div>
          )}
        </div>
        <div className="link">
        <Link to={"/services"} className="btn"><span>demander un service</span> <i className="fa fa-chevron-right"></i></Link>
        </div>
    </div>
  )
}

export default Testimonials