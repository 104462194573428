import "./footer.css";
import { footer } from "../../../utils/data";
import { Link } from "react-router-dom";

const Footer = () => {
  let currentYear = new Date().getFullYear();
  const siteDate=currentYear===2023?  currentYear:"2023-"+ currentYear
  return (
    <div className="footer-container">
      <ul className="elements ">
        {footer.map((footer,index) =>
          <li className="footer-list flex " key={index}>
            <section className="footer-title">
              <p >
              <span className="title">{footer.title}</span>
              </p>
              {
                footer.title==="Contacts"?
                footer.text.map((title,index) =>
                <Link to={title.url} key={index} className="flex-col">
                  <span>{title.name}</span>
                </Link>
              ):
               footer.text.map((text,index) =>
              <p key={index} className="flex-col">
                <span>{text}</span>
              </p>)
              }
              </section>
          </li>

        )}
      </ul>
      <p className="developper">
                Fait avec amour et fraternité par Abdourahim Soumah . Contact:<Link
                style={{ backgroundColor: "green",padding: "2px 10px" }}
                 to={"https://wa.me/+224620999137"}>+224 620 99 91 37</Link> 
      </p>
      <p className="copyright">&copy; Tous les droits reservés . {siteDate} Abidjan République de Côte D'Ivoire</p>
    </div>
  )
}

export default Footer