import TeamCard from "./TeamCard/TeamCard"
import Landing from '../Landing/Landing';
import { announceText } from "../../utils/data";
import { team } from "../../utils/data";
import { landings } from '../../utils/data';
import "./team.css"

const Team = () => {
  return (
    <>
      <Landing landings={landings[2]}/>
      <div className="team-container">
        <h1 className="announce-title">Une liste exhaustive de notre équipe</h1>
        <p className="announce-text">{announceText[1].text2}</p> 
        <div className="only-main-page">
          <p className="text">Une équipe de spécialistes s'engage résolument à vos côtés pour tous vos problèmes.</p>
       </div>
        <div className="teams flex">
          {team.map((team,index) =>
            <div className="team " key={index}>
              <TeamCard team={team}/>
            </div>
          )}
        </div>
    </div>
    </>
  )
}

export default Team