import React from 'react'
import "./landing.css"

const Landing = ({landings}) => {
  return (
    <div className='landing-container'>
        <p className="landing-image">
            <img src={landings.img} alt="landing " />
        </p>
    </div>
  )
}

export default Landing